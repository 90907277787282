import React from 'react';
import './css/general.css'

export const FarmIdToView = (farmId) => {
    return farmId.toString().padStart(9, '0')
}

export const setSelectedFarmName = (farmId) => {
    localStorage.setItem('selectedFarmName', JSON.stringify(farmId));
}

export const getSelectedFarmName = () => {
    let s = localStorage.getItem('selectedFarmName');

    if (s === "undefined" || s === "") return '';

    return JSON.parse(s) || '';
}

export const setSelectedFarmId = (farmId) => {
    localStorage.setItem('selectedFarm', JSON.stringify(farmId));
}

export const getSelectedFarmId = () => {
    let s = localStorage.getItem('selectedFarm');

    if (s === "undefined" || s === "") return '';

    return JSON.parse(s) || 0;
}

export const removeSelectedFarmId = () => {
    localStorage.removeItem('selectedFarm');
}

export const setSelectedYear = (year) => {
    localStorage.setItem('selectedYear', JSON.stringify(year));
}

export const getSelectedYear = () => {
    return JSON.parse(localStorage.getItem('selectedYear')) || new Date().getFullYear();
}

export const removeSelectedYear = () => {
    localStorage.removeItem('selectedYear');
}

export const setSelectedPlot = (plot) => {
    localStorage.setItem('selectedPlot', JSON.stringify(plot));
}

export const getSelectedPlot = () => {
    return JSON.parse(localStorage.getItem('selectedPlot')) || null;
}

export const removeSelectedPlot = () => {
    localStorage.removeItem('selectedPlot');
}

/* Languages */
export const setSelectedLang = (lg) => {
    localStorage.setItem('selectedLanguage', JSON.stringify(lg));
}

export const getSelectedLang = () => {
    return JSON.parse(localStorage.getItem('selectedLanguage')) || 'fi';
}

export const setGPStoStorage = (value) => {
    localStorage.setItem('GPSSelected', JSON.stringify(value));
}

export const getGPSfromStorage = () => {
    return JSON.parse(localStorage.getItem('GPSSelected')) || false;
}

export const setSelectedPage = (value) => {
    localStorage.setItem('getSelectedPage', JSON.stringify(value));
}

export const getSelectedPage = () => {
    return JSON.parse(localStorage.getItem('getSelectedPage')) || 0;
}

export const setSelectedPlantsToView = (value) => {
    localStorage.setItem('selectedPlantsToView', JSON.stringify(value));
}

export const getSelectedPlantsToView = () => {
    return JSON.parse(localStorage.getItem('selectedPlantsToView')) || 0;
}

export const setSelectedPlotsToView = (value) => {
    localStorage.setItem('selectedPlotsToView', JSON.stringify(value));
}

export const getSelectedPlotsToView = () => {
    return JSON.parse(localStorage.getItem('selectedPlotsToView')) || 0;
}

/* PlantId for navigation */
export const setSelectedPlantId = (plantId) => {
    localStorage.setItem('selectedPlantId', JSON.stringify(plantId));
}

export const getSelectedPlantId = () => {
    return JSON.parse(localStorage.getItem('selectedPlantId')) || -1;
}

export const removeSelectedPlantId = () => {
    localStorage.removeItem('selectedPlantId');
}
/*---*/

/* PlotGroupId for navigation */
export const setSelectedPlotGroupId = (plotId) => {
    localStorage.setItem('selectedPlotGroupId', JSON.stringify(plotId));
}

export const getSelectedPlotGroupId = () => {
    return JSON.parse(localStorage.getItem('selectedPlotGroupId')) || -1;
}

export const removeSelectedPlotGroupId = () => {
    localStorage.removeItem('selectedPlotGroupId');
}
/*--- */

/* Front page forecast station */
export const getSelectedForecastStation = () => {
    return JSON.parse(localStorage.getItem('selectedForecastStation')) || {stationId: -1};
}
export const setSelectedForecastStation = (stationId) => {
    localStorage.setItem('selectedForecastStation', JSON.stringify(stationId));
}

export const getSelectedHistoryStation = () => {
    return JSON.parse(localStorage.getItem('selectedHistoryStation')) || {stationId: -1};
}
export const setSelectedHistoryStation = (stationId) => {
    localStorage.setItem('selectedHistoryStation', JSON.stringify(stationId));
}
/* Front page weather station */
export const getSelectedWeatherStation = () => {
    return JSON.parse(localStorage.getItem('selectedWeatherStation')) || "";
}

export const setSelectedWeatherStation = (stationId) => {
    localStorage.setItem('selectedWeatherStation', JSON.stringify(stationId));
}

let key = (farmId, farmYear) => {
    return `mobiwisu-selected-plots-${farmId}-${farmYear}`;
};

export let retrieveSelectedPlotIds = (farmId, farmYear) => {
    var plotIds = JSON.parse(localStorage.getItem(key(farmId, farmYear)));
    return plotIds || [];
};

export const removeSelectedPlotIds = () => {

    let farm = getSelectedFarmId();
    let year = getSelectedYear();
    localStorage.setItem(key(farm, year), JSON.stringify([]));

}

export let saveSelectedPlotIds = (plotIds) => {
    if (plotIds && plotIds.length) {

        let farm = getSelectedFarmId();
        let year = getSelectedYear();
        let plots = retrieveSelectedPlotIds(farm, year);

        if (plots.some(p => plotIds.includes(p)))
            plots = plots.filter(item => !plotIds.includes(item)).map(c => c);
        else
            plots = [...plots, ...plotIds];

        localStorage.setItem(key(farm, year), JSON.stringify(plots));
    }
}

export let getSelectedPlots = () => {
    let farm = getSelectedFarmId();
    let year = getSelectedYear();
    return retrieveSelectedPlotIds(farm, year);
}

export let saveSelectedPlots = (plotId) => {
    if (plotId) {

        let farm = getSelectedFarmId();
        let year = getSelectedYear();
        let plots = retrieveSelectedPlotIds(farm, year);

        if (plots.some(p => p === plotId))
            plots = plots.filter(f => f !== plotId);
        else
            plots.push(plotId);

        localStorage.setItem(key(farm, year), JSON.stringify(plots));
    }
}

export const formatGroupLabel = data => {
    return (
        <div className="headerStyles">
            <div>{data.label}</div>
        </div>
    )
}

export const customStyles = {
    option: (styles, { data, isDisabled, isSelected, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? '#DCDCDC' : data.type === 'selectableHeader' ? '#E6E6FA' : isSelected ? '#E6E6FA' : isFocused ? '#E6E6FA' : undefined,
            color: isDisabled ? 'black' : undefined,
            fontWeight: isDisabled ? '700' : data.type === 'selectableHeader' ? '600' : undefined,
            fontSize: isDisabled ? '107%' : data.type === 'selectableHeader' ? '103%' : undefined,            
            lineHeight: isDisabled ? '2' : undefined,
            minWidth: isDisabled ? '2' : undefined            
        };
    }
};



/* Allowed farms */
export const setFarmList = (list) => {
    sessionStorage.setItem('userFarmIds', JSON.stringify(list));
}

export const getProLicence = () => {
    return sessionStorage.getItem('proLicenses');
}

export const setProLicence = (value) => {
    sessionStorage.setItem('proLicenses', value);
}

export const farmHaveUIWriteRights = () => {
    let rValue = false;
    let farmId = getSelectedFarmId();
    try {
        let objList = JSON.parse(sessionStorage.getItem('userFarmIds')) || [];

        objList.forEach(d => {
            if (Number(d.id) === Number(farmId) && d.level) {
                rValue = true;
            }
        });

    } catch (e) {
        rValue = false;
    }
    return rValue;
}

/*MAP*/
// Store selected mapfeatures to localstorage
export let storeSelectedFeature = (info) => {
    localStorage.setItem('mobiwisu-map-selected-features', JSON.stringify(info));
};
// Retrieve and return seleted mapfeatures
export let retrieveSelectedFeature = () => {
    var selected = JSON.parse(localStorage.getItem('mobiwisu-map-selected-features'));
    return selected || [];
};
