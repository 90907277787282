import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';
import * as dayjs from 'dayjs'

import { postContentWithAction } from '../../apiConnect';
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle } from '../ComponentStyles/ButtonStyles.js'
import { getSelectedPlot, getSelectedLang } from '../../common';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { useAlert } from 'react-alert'
import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, formatPlannedDate, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'
import '../../css/plotOperation.css'
import '../../css/general.css'

const PlantProtectionAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [plantProtections, setPlantProtections] = useState([]);
    const [memosIsLoading, setMemosIsLoading] = useState(true);
    const [memos, setMemos] = useState([]);
    const [plantProtectionIsLoading, setPlantProtectionIsLoading] = useState(true);

    const p = getSelectedPlot();
    const alert = useAlert();

    useEffect(() => {
        if (getSelectedPlot()) {
            setPlantProtectionIsLoading(true);
            setPlantProtections([]);

            if (p && p.apNumber) {
                var plotIds = [];
                plotIds.push({ plotId: p.apNumber });
                postContentWithAction(auth.getAccessToken(), 'plotOperation', 'getPlantProtectionOperations', { models: plotIds, lang: getSelectedLang() }, (protections) => {
                    setPlantProtectionIsLoading(false);
                    let sortedplotList = compareProtections(protections);
                    setPlantProtections(sortedplotList);
                });
            }
        }
    }, [props.refresh]);

    useEffect(() => {
        setMemosIsLoading(true);
        var memos = props.memos.filter(m => m.plantMemoType === 4);
        memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        setMemos(memos);
        setMemosIsLoading(false);
    }, [props.memos])

    const compareProtections = (data) => {
        //Regular items
        let doneProtections = data.filter(p => {
            if (p.growthInformation.date)
                return p;
        });   
        let plannedProtections = data.filter(p => {
            if (!p.growthInformation.date && p.growthInformation.plannedDate)
                return p;
        });            
        //Special cases     
        let specialObservationsDone = data.filter(p => {
            if (p.growthInformation.growthStage == 0 && !p.growthInformation.growthStageName && p.growthInformation.date && !p.pesticides && !p.fertilizings)
                return p;
        });
        let specialObservationsPlanned = data.filter(p => {
            if (p.growthInformation.growthStage == 0 && !p.growthInformation.growthStageName && !p.growthInformation.date && !p.pesticides && !p.fertilizings)
                return p;
        });
        let datelessProtections = data.filter(p => {
            if (!p.growthInformation.date && !p.growthInformation.plannedDate)
                return p;
        });         

        //DONE protections 
        doneProtections.sort((a, b) => {
            return comparisonExtenderDoneDates(a, b);
        });

        //PLANNED protections 
        plannedProtections.sort((a, b) => {
            return comparisonExtenderPlannedDates(a, b);
        });

        //DONE SPECIAL OBSERVATIONS  
        specialObservationsDone.sort((a, b) => {
            return comparisonExtenderDoneDates(a, b);
        });

        //PLANNED SPECIAL OBSERVATIONS  
        specialObservationsPlanned.sort((a, b) => {
            return comparisonExtenderPlannedDates(a, b);
        });

        //DATELESS PROTECTIONS  
        datelessProtections.sort((a, b) => {
            return comparisonExtenderGrowthStages(a, b);
        });

        let sortedProtections = [...doneProtections, ...plannedProtections, ...specialObservationsDone, ...specialObservationsPlanned, ...datelessProtections];

        return sortedProtections;
    };

    //Comparison first layer - DONE DATES
    const comparisonExtenderDoneDates = (a, b) => {
        //FIRST layer filtering based on ASCENDING DATE order
        if (new Date(a.growthInformation.date) > new Date(b.growthInformation.date)) {
            return 1;
        }
        if (new Date(a.growthInformation.date) < new Date(b.growthInformation.date)) {
            return -1;
        }
        if (dayjs(a.growthInformation.date).isSame(dayjs(b.growthInformation.date))) {
            //SECOND layer filtering, based on growthStage
            return comparisonExtenderGrowthStages(a, b);
        }
        return -1;
    };
    //Comparison first layer - PLANNED DATES
    const comparisonExtenderPlannedDates = (a, b) => {
        //FIRST layer filtering based on ASCENDING DATE order
        if (Number(a.growthInformation.plannedDate.slice(4, 6)) > Number(b.growthInformation.plannedDate.slice(4, 6))) {
            return 1;
        }
        if (Number(a.growthInformation.plannedDate.slice(4, 6)) < Number(b.growthInformation.plannedDate.slice(4, 6))) {
            return -1;
        }
        if (Number(a.growthInformation.plannedDate.slice(4, 6)) === Number(b.growthInformation.plannedDate.slice(4, 6))) {
            //SECOND layer filtering, based on growthStage
            return comparisonExtenderGrowthStages(a, b);
        }
        return -1;
    };
    //Comparison extender for second layer of comparisons - GROWTH STAGES
    const comparisonExtenderGrowthStages = (a, b) => {
        //NO GROWTH STAGE
        if (a.growthInformation.growthStage == 0 && !a.growthInformation.growthStageName && !b.growthInformation.growthStage == 0 && b.growthInformation.growthStageName) {
            return 1;
        }
        if (!a.growthInformation.growthStage == 0 && a.growthInformation.growthStageName && b.growthInformation.growthStage == 0 && !b.growthInformation.growthStageName) {
            return -1;
        }
        if (a.growthInformation.growthStage == 0 && !a.growthInformation.growthStageName && b.growthInformation.growthStage == 0 && !b.growthInformation.growthStageName) {
            ///OBSERVATIONS 
            return comparisonExtenderObservations(a, b);
        }
        /// GROWTHSTAGES
        if (a.growthInformation.growthStage && b.growthInformation.growthStage) {
            /// T-type of growth stage
            if (!a.growthInformation.growthStage.includes('T') && b.growthInformation.growthStage.includes('T')) {
                return 1;
            }
            if (a.growthInformation.growthStage.includes('T') && !b.growthInformation.growthStage.includes('T')) {
                return -1;
            }
            if (a.growthInformation.growthStage.includes('T') && b.growthInformation.growthStage.includes('T')) {
                if (Number(a.growthInformation.growthStage.slice(-1)) > Number(b.growthInformation.growthStage.slice(-1))) {
                    return 1;
                }
                if (Number(a.growthInformation.growthStage.slice(-1)) < Number(b.growthInformation.growthStage.slice(-1))) {
                    return -1;
                }
                // Identical T-type growthstages
                if (Number(a.growthInformation.growthStage.slice(-1)) === Number(b.growthInformation.growthStage.slice(-1))) {
                    ///THIRD layer - observations 
                    return comparisonExtenderObservations(a, b);
                }
            }
            /// normal, numerical types of growthstages
            if (!a.growthInformation.growthStage.includes('T') && !b.growthInformation.growthStage.includes('T')) {
                if (!Number(a.growthInformation.growthStage.charAt(0)) === 0 && Number(b.growthInformation.growthStage.charAt(0)) === 0) {
                    return 1;
                }
                if (Number(a.growthInformation.growthStage.charAt(0)) === 0 && !Number(b.growthInformation.growthStage.charAt(0)) === 0) {
                    return -1;
                }
                //Growthstages Header
                if (a.growthInformation.growthStage.charAt(0) == 0 && b.growthInformation.growthStage.charAt(0) == 0) {
                    if (Number(a.growthInformation.growthStage) > Number(b.growthInformation.growthStage)) {
                        return 1;
                    }
                    if (Number(a.growthInformation.growthStage) < Number(b.growthInformation.growthStage)) {
                        return -1;
                    }
                    if (Number(a.growthInformation.growthStage) === Number(b.growthInformation.growthStage)) {
                        ///THIRD layer - observations 
                        return comparisonExtenderObservations(a, b);
                    }
                }
                //Not header
                if (!a.growthInformation.growthStage.charAt(0) == 0 && !b.growthInformation.growthStage.charAt(0) == 0) {
                    if (Number(a.growthInformation.growthStage) > Number(b.growthInformation.growthStage)) {
                        return 1;
                    }
                    if (Number(a.growthInformation.growthStage) < Number(b.growthInformation.growthStage)) {
                        return -1;
                    }
                    if (Number(a.growthInformation.growthStage) === Number(b.growthInformation.growthStage)) {
                        ///THIRD layer - observations 
                        return comparisonExtenderObservations(a, b);
                    }
                }
            }
        }
    };
    //Comparison extender for third layer of comparisons - OBSERVATIONS
    const comparisonExtenderObservations = (a, b) => {
        //NO OBSERVATION 
        if (!a.observations.length && b.observations.length) {
            return 1;
        }
        if (a.observations.length && !b.observations.length) {
            return -1;
        }
        if (!a.observations.length && !b.observations.length) {
            ///PESTICIDES 
            return comparisonExtenderPesticides(a, b);
        }
        if (a.observations.length && b.observations.length) {
            if (a.observations[0].productName > b.observations[0].productName) {
                return 1;
            }
            if (a.observations[0].productName < b.observations[0].productName) {
                return -1;
            }
            if (a.observations[0].productName === b.observations[0].productName) {
                ///FOURTH layer - pesticides 
                return comparisonExtenderPesticides(a, b);
            }
        }
    };
    //Comparison extender for fourth layer of comparisons - PESTICIDES
    const comparisonExtenderPesticides = (a, b) => {
        //NO PESTICIDES 
        if (!a.pesticides.length && b.pesticides.length) {
            return 1;
        }
        if (a.pesticides.length && !b.pesticides.length) {
            return -1;
        }
        if (!a.pesticides.length && !b.pesticides.length) {
            ///FERTILIZINGS 
            return comparisonExtenderFertilizings(a, b);
        }
        if (a.pesticides.length && b.pesticides.length) {
            ///FOURTH layer - pesticides      
            if (a.pesticides[0].productName > b.pesticides[0].productName) {
                return 1;
            }
            if (a.pesticides[0].productName < b.pesticides[0].productName) {
                return -1;
            }
            ///FIFTH layer - fertilizings 
            if (a.pesticides[0].productName === b.pesticides[0].productName) {
                return comparisonExtenderFertilizings(a, b);
            }
        }
    };
    //Comparison extender for fifth layer of comparisons - FERTILIZINGS
    const comparisonExtenderFertilizings = (a, b) => {
        //NO PESTICIDES 
        if (!a.fertilizings.length && b.fertilizings.length) {
            return 1;
        }
        if (a.fertilizings.length && !b.fertilizings.length) {
            return -1;
        }
        if (!a.fertilizings.length && !b.fertilizings.length) {
            ///FERTILIZINGS 
            return 0;
        }
        if (a.fertilizings.length && b.fertilizings.length) {
            ///FIFTH layer - fertilizings             
            if (a.fertilizings[0].productName > b.fertilizings[0].productName) {
                return 1;
            }
            if (a.fertilizings[0].productName < b.fertilizings[0].productName) {
                return -1;
            }
        }
    };

    const getHeaderText = (p, rowLetter) => {
        let displayText = [];

        if (p.growthInformation && p.growthInformation.growthStage && p.growthInformation.growthStage !== "0") {
            let growthStage = p.growthInformation.growthStage.slice(2, 4) + " - " + p.growthInformation.growthStageName;
            displayText.push(growthStage);
        }

        if (p.observations.length) {
            p.observations.forEach(obs => {
                displayText.push(obs.productName);
            });
        }

        if (!displayText.length) {
            let text = rowLetter ? "-" : t('NoGrowthStageOrObservation');
            displayText.push(text);
        }

        return displayText.join(' - ');
    }

    const getSubText = (p) => {
        let displayText = [];

        if (p.pesticides.length) {
            p.pesticides.forEach(pest => {
                displayText.push(pest.productName);
            });
        }

        return displayText.join(' - ');
    }

    const getSecondSubText = (p) => {
        let displayText = [];

        if (p.fertilizings.length) {
            p.fertilizings.forEach(fert => {
                displayText.push(fert.productName);
            });
        }

        return displayText.join(' - ');
    }

    const setPesticideDone = (pest) => {
        var growthInformation = pest.growthInformation;
        // Updating individual sow to show spinner
        const updatedPPs = plantProtections.map(s => s === pest ? { ...s, saveInProgress: true } : s);
        setPlantProtections(updatedPPs);

        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'done', { growthInfo: growthInformation }, () => {
            // Updating individual sow to hide spinner
            const updatedPPs = plantProtections.map(s => s === pest ? { ...s, saveInProgress: false } : s);
            setPlantProtections(updatedPPs);
            setPlantProtectionIsLoading(false);
            props.setRefresh(Date.now());
        }).catch(e => {
            const updatedPPs = plantProtections.map(s => s === pest ? { ...s, saveInProgress: false } : s);
            setPlantProtections(updatedPPs);
            setPlantProtectionIsLoading(false);
            alert.show(t('SavedFailed'), { type: 'error' });
        });
    }

    const getRowLetter = (row) => {
        return getHeaderText(row, true).substring(0, 1);
    }

    return (
        <Card className="border-bottom-1">
            <Card.Header className={props.activeCard === Cards.pesticideCardId ? 'active-panel' : 'inactive-panel'}>
                <OperationCardToggle classname={props.activeCard === Cards.pesticideCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.pesticideCardId, props.setActiveCard)} eventKey="3">
                    {t('Observations_PlantProtection')}
                    {plantProtectionIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{plantProtections ? plantProtections.length : 0}</Badge>}
                </OperationCardToggle>
                <Button style={addButtonStyle} variant='mmfi-add-btn' className="float-right" onClick={() => {
                    props.setSelectEvent({ time: Date.now(), selectedElement: null });
                    props.setModalShow(true);
                }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
                <Card.Body>
                    <ListGroup>
                        {(plantProtections) ? plantProtections.map((p, i) =>
                            <div key={p.growthInformation.pesticideId} id={p.growthInformation.pesticideId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-operation'>{getRowLetter(p)}</div>

                                <ListGroup.Item key={i} style={rowStyle} className={!p.growthInformation.date ? "plot-operation-row" : "plot-operation-row-done"} action onClick={() => {
                                    props.setSelectEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true);
                                }}>

                                    <div><b>{getHeaderText(p)}</b></div>
                                    <div>{getSubText(p)}</div>
                                    <div>{getSecondSubText(p)}
                                        {getSecondSubText(p) ?
                                            <span className="tank-mixture-icon" title={t('TankMixture')}><Icon icon={ICONS.INFO} color='#2ba3c4' size='20' /></span>
                                            : ''}
                                    </div>
                                    
                                    <div>{(p.growthInformation.date) ?
                                        <span className='row-subtext'>{t('Completed')} {dayJsFormatDate(p.growthInformation.date)}</span>
                                        : <span className='row-subtext'>{formatPlannedDate(p.growthInformation.plannedDate, t)}</span>}
                                    </div>
                                </ListGroup.Item>

                                {props.allowedSave ?
                                    <div className="set-done-box">
                                        {p.saveInProgress ? <Spinner className="mark-spin" as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                                        {!p.growthInformation.date && p.growthInformation.plannedDate ?
                                            <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-mobile"} onClick={() => setPesticideDone(p)}>{t('MarkDoneShort')}</Button>
                                            : <div className="done-box float-right mark-spin"><Icon icon={ICONS.MARK_OK} color='green' size='24' /></div>}
                                        {!p.growthInformation.date && p.growthInformation.plannedDate ?
                                            <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-desktop"} onClick={() => setPesticideDone(p)}>{t('MarkDone')}</Button>
                                            : <div className="done-box float-right mark-spin"><Icon icon={ICONS.MARK_OK} color='green' size='24' /></div>}
                                    </div>
                                : ''}
                            </div>
                        ) : ''}
                        {!props.memosIsLoading ? memos.map(p =>
                            <div key={p.memoId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                <ListGroup.Item className="plot-memo-row" key={p.memoId} style={rowStyle} action onClick={() => {
                                    props.setMemoType(4);
                                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                    props.setMemoModalShow(true);
                                }}>
                                    <div><b> {t('Note')}</b></div>
                                    <div className='row-subtext'>{p.memo}</div>
                                </ListGroup.Item>
                                <span style={textStyle}></span>
                            </div>
                        ) : <div>
                            {t('Loading')}
                            <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                        </div>}
                        <ListGroup.Item style={addNoteRowStyle} action
                            onClick={() => {
                                props.setMemoType(4);
                                props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                                props.setMemoModalShow(true);
                            }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                            {memosIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default PlantProtectionAccordionCard;