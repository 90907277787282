import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Button from 'react-bootstrap/Button';
import { ICONS } from './img/icons'
import Icon from './img/Icon'
import { WiThermometer, WiStrongWind, WiHumidity, WiHorizonAlt } from "react-icons/wi";
import { SiRainmeter } from "react-icons/si";
import { CiSun } from "react-icons/ci";
import { GiForwardSun } from "react-icons/gi";
import { RiWindyFill } from "react-icons/ri";
import { TbDelta, TbDropletUp, TbDropletHalf2Filled, TbCircleDotFilled } from "react-icons/tb";
import { ImLeaf } from "react-icons/im";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    PiSunHorizonFill, PiDropHalfBottomBold, PiBatteryVerticalFullFill, PiBatteryVerticalHighFill,
    PiBatteryVerticalMediumFill, PiBatteryVerticalLowFill, PiBatteryVerticalEmptyFill
} from "react-icons/pi";
import { DiCodrops } from "react-icons/di";
import { IoIosArrowRoundUp } from "react-icons/io";
import { MeasurementType } from './Constants/MeasurementTypes.js';

const iconStyle = {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '5px',
    marginTop: '3px'
}

const inventoryIconStyle = {
    display: 'inline-block',
    marginRight: '5px',
    marginBottom: '8px'
}

export const GetLogoutIcon = () =>
(
    <Icon icon={ICONS.LOGOUT} color='black' size='25' style={iconStyle} />
);

export const GetGroupIcon = () =>
(
    <Icon icon={ICONS.GROUP} color='black' size='22' style={iconStyle} />
);

export const GetPlantIcon = () =>
(
    <Icon icon={ICONS.PLANT} color='black' size='22' style={iconStyle} />
);

export const GetPlotsIcon30 = () =>
(
    <Icon icon={ICONS.PLOTS} color='black' size='28' style={iconStyle} />
);

export const GetPlotsIcon = () =>
(
    <Icon icon={ICONS.PLOTS} color='black' size='22' style={iconStyle} />
);

export const GetEditIcon = () =>
(
    <Icon icon={ICONS.EDIT} color='black' size='22' style={iconStyle} />
);


export const GetFertilityIcon = () =>
(
    <Icon icon={ICONS.FERTILITYSAMPLE} color='black' size='22' style={iconStyle} />
);

export const GetInventoryIcon = () =>
(
    <Icon icon={ICONS.INVENTORY} color='black' size='34' style={inventoryIconStyle} />
);

export function OperationCardToggle({ children, eventKey, callback, classname }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            className={classname + " text-dark btn btn-text section-button"}
            onClick={() => decoratedOnClick()}
        >
            {children}
        </button>
    );
}

export const AccordionButtonToggle = ({ children, eventKey, callback, className, translation }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const customClick = useAccordionButton(eventKey, () => { });
    const isCurrentEventKey = activeEventKey === eventKey;
    const t = translation;

    return (<Button className={className} onClick={() => {
        customClick();
        callback && callback();
    }}>{children} {isCurrentEventKey ? t('ShowLess') : t('ShowMore')}</Button>);
}

export const getMeasurementIcon = (type, size) => {
    var iconColor = 'black';

    switch (type) {
        case MeasurementType.AirTemperature:
            return <WiThermometer className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.Precipitation:
            return <SiRainmeter className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.SolarRadiation:
            return <CiSun className="weather-type-icon" fontSize={30} color={iconColor} />;
        case MeasurementType.WindSpeed:
            return <WiStrongWind className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.WindGust:
            return <RiWindyFill className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.RelativeHumidity:
            return <WiHumidity className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.DewPoint:
            return <Row >
                <Col><WiThermometer fontSize={size} color={iconColor} /></Col>
                <Col><TbDropletHalf2Filled fontSize="18" color={iconColor} /></Col>
                </Row>
        case MeasurementType.DailyET0:
            return <TbDropletUp className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.DeltaT:
            return <TbDelta className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.Sunrise:
            return <WiHorizonAlt className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.Sunset:
            return <PiSunHorizonFill className="weather-type-icon" fontSize={size} color={iconColor} />;
        case MeasurementType.LeafWetness:
            return <ImLeaf className="weather-type-icon" fontSize={18} color={iconColor} />;
        case MeasurementType.VPD:
            return <PiDropHalfBottomBold className="weather-type-icon" fontSize={23} color={iconColor} />;
        case MeasurementType.SunshineDuration:
            return <GiForwardSun className="weather-type-icon" fontSize={29} color={iconColor} />;
        case MeasurementType.SoilMoisture:
            return <DiCodrops className="weather-type-icon" fontSize={31} color={iconColor} />;
        case MeasurementType.SoilTemperature:
            return <TbCircleDotFilled className="weather-type-icon" fontSize={size} color={iconColor} />;
        default:
            return <div></div>;
    }
}