import { MeasurementType } from '../../Constants/MeasurementTypes.js';

const drawArrow = (ctx, x, y, degrees, size) => {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.rotate((degrees - 180) * Math.PI / 180);

    // Draw arrow
    ctx.moveTo(0, -size / 2);
    ctx.lineTo(size / 4, size / 2);
    ctx.lineTo(-size / 4, size / 2);
    ctx.closePath();
    ctx.restore();
};


export const WindDirectionPlugin = {
    id: 'windDirection',
    afterDatasetsDraw: (chart, args, options) => {
        const { ctx, scales: { x }, chartArea } = chart;
        const wind = chart.data.datasets.find((d: any) => d.measurementType === MeasurementType.WindSpeed || d.measurementType === MeasurementType.WindGust);

        if (!wind) return;

        const size = options.size || 10;
        ctx.fillStyle = options.color || '#000000';

        // Calculate y position at bottom of chart (with small offset)
        const yPos = chartArea.bottom - (size * 0.8);

        const totalPoints = wind.data.length;
        const step = Math.max(1, Math.floor(totalPoints / 14));

        for (let i = 0; i < totalPoints; i += step) {
            const direction = wind.windDirection[i];

            if (direction === null) continue;

            const xPos = x.getPixelForValue(chart.data.labels[i]);

            drawArrow(ctx, xPos, yPos, direction, size);
            ctx.fill();
        }
    }

};

export default WindDirectionPlugin;