import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { getContentWithAction, getContent } from '../../apiConnect';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Spinner from 'react-bootstrap/Spinner';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'

/* Map components */
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import { addMapBaseLayer } from '../../Map/addMapBaseLayer';

import 'ol/ol.css';
import '../../css/map.css';
import '../../css/general.css'


import {
    getGPSfromStorage, getSelectedPlots, getSelectedLang, getSelectedPlot, getSelectedYear, getSelectedFarmId, getSelectedWeatherStation
} from '../../common';
import AppContext from '../../AppContext';
import * as styles from '../../Map/mapStyle'


const WeatherStationMapModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [mapIsRendered, setMapIsRendered] = useState(false);
    const [pageIsLoading, setPageIsLoading] = useState(true);
    const [allowChanges, setAllowChanges] = useState(false);

    const [selectedStation, setSelectedStation] = useState(null);
    const [stations, setStations] = useState([]);

    const mapRef = useRef(null);
    const popupRef = useRef(null);
    const [map, setMap] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);


    useEffect(() => {
        styles.setLang(t);
        setPageIsLoading(true);

        if (!mapIsRendered && props.show) {
            Promise.all([
                getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang() }),
                getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}),
                getContentWithAction(auth.getAccessToken(), 'weatherStation', 'getWeatherStations', {}),
            ]).then(([data, session, stationData]) => {
                setPageIsLoading(false);
                setAllowChanges(session);                
                if (stationData) {
                    var currentStationId = props.currentStation.stationId;
                    setStations(stationData);
                    var selectedStationId = getSelectedWeatherStation();
                    var selectedStation = stationData.find(s => s.stationId === currentStationId);
                    // TODO: get farm station id
                    selectedStation ? setSelectedStation(selectedStation) : setSelectedStation(stationData[0]);
                }
            });
        }
    }, [props.show]);

    useEffect(() => {
        if (!mapRef.current || !stations || !selectedStation || mapIsRendered) return;

        let basicImage = addMapBaseLayer("taustakartta");
        basicImage.values_.zIndex = 0; // Set zIndex to 0 to make sure it's below the vector layer
        basicImage.setVisible(true);

        // Define feature styles
        const normalStyle = new Style({
            image: new Circle({
                radius: 12,
                fill: new Fill({ color: '#1242A3' }),
                stroke: new Stroke({ color: '#1242A3', width: 2 })
            })
        });

        const normalStyleSelected = new Style({
            image: new Circle({
                radius: 12,
                fill: new Fill({ color: '#fff' }),
                stroke: new Stroke({ color: '#1242A3', width: 2 })
            })
        });

        const hoverStyle = new Style({
            image: new Circle({
                radius: 15,
                fill: new Fill({ color: '#fff' }),
                stroke: new Stroke({ color: '#1242A3', width: 3 })
            })
        });

        // Create popup overlay
        const popup = new Overlay({
            element: popupRef.current,
            positioning: 'bottom-center',
            offset: [0, -18]
        });

        // Create features with normal style
        const features = stations.map(point => {
            const feature = new Feature({
                geometry: new Point(fromLonLat(point.coordinates)),
                ...point
            });

            if (point.stationId === selectedStation.stationId) {
                feature.setStyle(normalStyleSelected);
                // Set popup content
                const coords = feature.getGeometry().getCoordinates();
                const properties = feature.getProperties();
                feature.setStyle(hoverStyle);
                setSelectedPoint(stations.find(s => s.stationId === properties.stationId));
                popup.setPosition(coords);
            }
            else {
                feature.setStyle(normalStyle);
            }

            return feature;
        });

        // Add pointer interaction styles
        const pointerStyle = new Style({
            image: new Circle({
                radius: 12,
                fill: new Fill({ color: '#1242A3' }),
                stroke: new Stroke({ color: '#fff', width: 2 })
            })
        });

        // Create vector layer with pointer interactions
        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features
            }),
            style: pointerStyle
        });

        // Initialize map
        const initialMap = new Map({
            target: mapRef.current,
            layers: [
                basicImage,
                vectorLayer,
            ],
            view:
                new View({
                    center: fromLonLat([25, 62]),
                    zoom: 6
                }),
            overlays: [popup]
        });


        // click handler for points
        initialMap.on('click', (event) => {
            var features = vectorLayer.getSource()?.getFeatures();
            features.forEach(feature => {
                const properties = feature.getProperties();
                feature.setStyle(normalStyle);
            });

            const pixel = initialMap.getEventPixel(event.originalEvent);
            const hit = initialMap.hasFeatureAtPixel(pixel);

            initialMap.getViewport().style.cursor = hit ? 'pointer' : '';

            const feature = initialMap.forEachFeatureAtPixel(
                event.pixel,
                (feature) =>
                    feature,
                {
                    hitTolerance: 12
                }
            );

            if (feature) {
                const coords = feature.getGeometry().getCoordinates();
                const properties = feature.getProperties();
                feature.setStyle(hoverStyle);
                setSelectedPoint(stations.find(s => s.stationId === properties.stationId));
                popup.setPosition(coords);
            }
            else {
                // return to normal style
                features.forEach(feature => {
                    // get feature properties
                    const properties = feature.getProperties();
                    feature.setStyle(normalStyle);
                });

                setSelectedPoint(null);
                popup.setPosition(undefined);
            }
        });

        // Update hover handler with pixel tolerance
        initialMap.on('pointermove', (event) => {
            
        });

        // Reset styles when leaving features
        initialMap.on('moveend', () => {
            
        });

        setMap(initialMap);
        setMapIsRendered(true);


        return () => {
            initialMap.setTarget(undefined);
        };

    }, [stations, selectedStation]);

    return (
        <>
            <Modal style={{ height: "100%" }} show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {t('Map')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "100%", padding: 0 }}>

                    {pageIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                        <>
                            <div ref={mapRef} className="map modal-map" />
                            <div ref={popupRef} className="map-popup">
                                {selectedPoint && (
                                    <div className="map-popup-content">
                                       {selectedPoint.deviceName + " - " + selectedPoint.stationId}
                                    </div>
                                )}
                            </div>
                        </>
                    }


                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={() => { props.onHide(); }}>{t('Back')}</Button>
                    {allowChanges ? 
                        <Button variant="success" className="btn-show-more" onClick={() => {
                            props.onSave(selectedPoint);
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WeatherStationMapModal;