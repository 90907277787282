import { UserManager } from 'oidc-client';
import { removeSelectedFarmId, removeSelectedYear, removeSelectedPlot, getSelectedPage } from '../common';
import { postContentWithAction } from '../apiConnect';

export default class Auth {
    constructor(history, settingss) {
        this.history = history;
        this.user = undefined;
        this.cacheSettings = settingss;

        // identity settings from AuthController
        settingss.automaticSilentRenew = true;
        settingss.revokeAccessToken = true;
        settingss.filterProtocolClaims = true;

        this.userManager = new UserManager(settingss);

        // before expiring, event activate
        this.userManager.events.addAccessTokenExpiring(() => {
            this.login();
        });
    }

    login = () => {
        this.userManager
            .signinRedirect()
            .catch(function (error) {
                console.error('error while logging in', error);
            });
    };

    ClearHttContextSession = () => {
        // api -> Backend Session.Clear()
        if (this.user && this.user.access_token)
            postContentWithAction(this.user.access_token, 'session', 'delete', {}, () => {
                //console.log('session deleted');
            });
    };

    logout = () => {
        this.ClearHttContextSession(); 

        // mobi selected settings
        removeSelectedFarmId();
        removeSelectedYear();
        removeSelectedPlot();

        // signout
        this.userManager.signoutRedirect().catch(function (error) {
            console.error('error while loggin out', error);
        });
    }

    handleAuthentication = (hash) => {   
        new UserManager({ response_mode: "query" }).signinCallback().then((user) => {
            this.user = user;

            let page = getSelectedPage();

            //transfer to main pages.
            if ((page > 1) && (!this.cacheSettings || this.cacheSettings.debug_mode !== "true"))
                page = 1;

            if (page === 1)
                this.history.push('/plots');
            else if (page === 2)
                this.history.push('/plotOperation');
            else if (page === 3)
                this.history.push('/mapViewer');
            else
                this.history.push('/');

        }).catch((error) => {
            this.login();
        });
    }

    showWeatherStationData = () => {
        if (this.cacheSettings.show_weather_station_data === "true") return true;
        return false;
    }

    showForecasts = () => {
        if (this.cacheSettings.show_forecasts == "true") return true;
        return false;
    }

    getAccessToken = () => {
        return this.user.access_token;
    }

    isAuthenticated = () => {
        if (this.user) {

            if (this.user.expires_at && this.user.access_token) {

                if ((new Date().getTime() / 1000) < this.user.expires_at)
                    return true;
            }
        }

        //else not logged
        this.ClearHttContextSession();
        return false;
    }
}