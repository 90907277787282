import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
//import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock';
import { chartMode, chartLib } from '../../constants';
import { getContent, getContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { dayJsFormatDate } from '../../commonFunctions.js'
import { getRainSumChartData, getTemperatureChartData, getTemperatureChartOptions, getRainSumChartOptions, getDValueChartData, getDValueChartOptions, getGrowthStageChartData, getGrowthStageOptions } from '../../Charts/ChartJsOptions.js' 
import {
    getSelectedLang, getSelectedForecastStation, getSelectedHistoryStation, getSelectedYear
} from '../../common';

import '../../css/general.css'
import '../../css/farmPlot.css'

/* ChartJS */
import ChartJs from "chart.js/auto";
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import { format, parseISO } from "date-fns";

const ChartModal = (props) => {
    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [chartOptions, setChartOptions] = useState(null);
    const [chartType, setChartType] = useState('');
    const [chartData, setChartData] = useState(null);
    const [chartLibrary, setChartLibrary] = useState(chartLib.chartJs);

    const [dValueData, setDValueData] = useState([]);
    const [growthData, setGrowthData] = useState([]);

    const [zoom, setZoom] = useState('2');
    const zoomSelections = [
        { name: t('Chart_Last_Month'), value: '1' },
        { name: t('All'), value: '2' }
    ]

    const tooltipLinePlugin = {
        id: "tooltipLine",
        afterDraw: (chart) => {
            if (chart.tooltip?.opacity === 1) {
                const { ctx } = chart;
                const { caretX } = chart.tooltip;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(caretX, topY - 5);
                ctx.lineTo(caretX, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';
                ctx.stroke();
                ctx.restore();
            }
        }
    };

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, tooltipLinePlugin);

    useEffect(() => {
        Highcharts.setOptions({ lang: getLangOptions() });
        if (props.chartMode === chartMode.temperatureSum) {
            setChartLibrary(chartLib.chartJs);
            setTitle(t('Forecast_HeatSum'));
            setChartType('LINE');
            setTemperatureOptions_ChartJs('2', true);
            //setChartOptions(HC_getTemperatureSumChartOptions());
            setIsLoading(false);
        }
        else if (props.chartMode === chartMode.rainSum) {
            setChartLibrary(chartLib.chartJs);
            setTitle(t('Forecast_RainSum'));
            setChartType('COLUMN');
            setRainSumOptions_ChartJs('2', true);
            //setChartOptions(HC_getRainSumChartOptions());
            setIsLoading(false);
        }
        else if (props.chartMode === chartMode.dValue) {
            setChartLibrary(chartLib.chartJs);
            setTitle(props.data.plotName + " - " + props.data.plotLetter);
            setChartType('LINE');
            handleDvalueChartLoading();
        }
        else if (props.chartMode === chartMode.growthStage) {
            setChartLibrary(chartLib.chartJs);
            setTitle(props.data.plotName + " - " + props.data.plotLetter);
            setChartType('LINE');
            handleGrowthChartLoading();
        }
        else {
            setChartLibrary(chartLib.chartJs);
            setTitle("CHART.JS - TESTING");
        }
    }, [props.data]);

    const handleDvalueChartLoading = () => {
        getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang() }).then((plotdata) => {
            var legumes = 0;
            var plot = plotdata.find(p => p.apNumber === props.data.plotId);

            if (plot.legumes == '1' || plot.legumes == 1) legumes = 20;
            if (plot.legumes == '2' || plot.legumes == 2) legumes = 40;

            var station = getSelectedForecastStation();
            var station30Y = getSelectedHistoryStation();
            getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastDValueChartData', { lang: getSelectedLang(), stationId: station.stationId, stationId30Y: station30Y.stationId, isLocalStation: station.localStation, clover: legumes }).then((data) => {
                //setChartOptions(HC_getDValueChartOptions(data));
                setDValueData(data);
                setDValueOptions_ChartJs(data, '2', true);
                setIsLoading(false);
            }).catch((c) => { setIsLoading(false); });
        }).catch((c) => { setIsLoading(false); });
    }

    const handleGrowthChartLoading = () => {
        var station = getSelectedForecastStation();
        var station30Y = getSelectedHistoryStation();

        getContentWithAction(auth.getAccessToken(), 'sow', 'sows', { plotId: props.data.plotId, lang: getSelectedLang() }).then((sows) => {
            var sow = sows.find(s => s.plotId === props.data.plotId);

            getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastGrowthChartData', {
                farmYear: getSelectedYear(),
                lang: getSelectedLang(),
                stationId: station.stationId,
                stationId30Y: station30Y.stationId,
                plantGroup: props.data.plantGroup,
                temperatureSum: props.data.temperatureSum,
                varietyClass: props.data.varietyClass,
                sowDate: dayjs((sow.sowingDate)).format(),
                isLocalStation: station.localStation
            }).then((data) => {
                //setChartOptions(HC_getGrowthStageOptions(data));
                setGrowthData(data);
                setGrowthStageOptions_ChartJs(data, '2', true);
                setIsLoading(false);
            }).catch((c) => { setIsLoading(false); });
        }).catch((c) => { setIsLoading(false); });
    }

    const resetChartData = (zoomValue) => {
        if (props.chartMode === chartMode.temperatureSum) {
            setTemperatureData_ChartJs(zoomValue);
        }
        else if (props.chartMode === chartMode.rainSum) {
            setRainSumData_ChartJs(zoomValue);
            //setRainSumOptions_ChartJs(zoomValue, false);
        }
        else if (props.chartMode === chartMode.dValue) {
            setDValueData_ChartJs(zoomValue);
        }
        else if (props.chartMode === chartMode.growthStage) {
            setGrowthStageData_ChartJs(zoomValue);
        }
    }

    const setRainSumData_ChartJs = (zoomValue) => {
        var data = getRainSumChartData(zoomValue, props.data, t);
        setChartData(data);
    }

    const setTemperatureData_ChartJs = (zoomValue) => {
        var data = getTemperatureChartData(zoomValue, props.data, t);
        setChartData(data);
    }

    const setDValueData_ChartJs = (zoomValue) => {
        var data = getDValueChartData(zoomValue, dValueData, t);
        setChartData(data);
    }

    const setGrowthStageData_ChartJs = (zoomValue) => {
        var data = getGrowthStageChartData(zoomValue, growthData, t);
        setChartData(data);
    }

    const setRainSumOptions_ChartJs = (zoomValue, formatData) => {
        if (formatData) {
            // If no data, return null --> hides the chart and shows error message
            if (props.data.temperatures.length === 0) {
                setChartData(null);
                return;
            }

            // Set chart options based on zoom value
            var chartData = getRainSumChartData(zoomValue, props.data, t);
            setChartData(chartData);
        }

        var tickMode = zoomValue === '1' ? 'day' : 'month';
        var options = getRainSumChartOptions(tickMode, getLangOptions());
        setChartOptions(options);
    }

    const setTemperatureOptions_ChartJs = (zoomValue, formatData) => {
        if (formatData) {
            // If no data, return null --> hides the chart and shows error message
            if (!props.data.temperatures.length) {
                setChartData(null);
                return;
            }

            var chartData = getTemperatureChartData(zoomValue, props.data, t);
            setChartData(chartData);
        }

        // Set chart options based on zoom value
        var tickMode = zoomValue === '1' ? 'day' : 'month';
        var options = getTemperatureChartOptions(tickMode, getLangOptions());
        setChartOptions(options);
    }

    const setDValueOptions_ChartJs = (data, zoomValue, formatData) => {
        if (formatData) {
            var chartdata = getDValueChartData(zoomValue, data, t);
            setChartData(chartdata);
        }

        var tickMode = zoomValue === '1' ? 'day' : 'month';
        var options = getDValueChartOptions(tickMode, getLangOptions());
        setChartOptions(options);
    }

    const setGrowthStageOptions_ChartJs = (data, zoomValue, formatData) => {
        if (formatData) {
            var chartdata = getGrowthStageChartData(zoomValue, data, t);
            setChartData(chartdata);
        }

        var tickMode = zoomValue === '1' ? 'day' : 'month';
        var options = getGrowthStageOptions(tickMode, getLangOptions());
        setChartOptions(options);
    }

    const HC_getRainSumChartOptions = () => {
        const options = {
            credits: {
                enabled: false
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                buttons: [{
                    type: 'week',
                    count: 2,
                    text: '2' + t('Chart_WeekText'),
                    title: 'View 2 week'
                }, {
                    type: 'month',
                    count: 1,
                    text: '1' + t('Chart_MonthText'),
                    title: 'View 1 month'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3' + t('Chart_MonthText'),
                    title: 'View 3 months'
                }, {
                    type: 'all',
                    text: t('Chart_ShowAll'),
                    title: 'View all'
                }],
                buttonSpacing: 10,
                buttonTheme: {
                    fill: '#eee',
                    width: 50,
                    r: 5,
                    style: {
                        color: '#039',
                        fontWeight: 'bold'
                    },
                    states: {
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                }
            },
            scrollbar: {
                enabled: true,
                height: 30,
            },
            title: {
                text: undefined
            },
            chart: {
                zoomType: 'xy'
            },
            yAxis: [{
                showInLegend: false,
                labels: {
                    format: '{value} mm',
                    align: "left",
                    x: 0
                },
                title: {
                    enabled: false
                },
                opposite: false,
                max: 450
            }],
            xAxis: [{
                type: 'datetime',
                crosshair: true,
            }],
            series: [{
                name: t('Forecast_RainSum'),
                type: 'column',
                yAxis: 0,
                data: props.data.dailyRains,
                tooltip: {
                    valueDecimals: 0,
                    valueSuffix: '{value} mm'
                },
                dataGrouping: {
                    enabled: false
                }
            }]
        };

        return options;
    };

    const HC_getTemperatureSumChartOptions = () => {
        const options = {
            credits: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1' + t('Chart_MonthText'),
                    title: 'View 1 month'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3' + t('Chart_MonthText'),
                    title: 'View 3 months'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6' + t('Chart_MonthText'),
                    title: 'View 6 months'
                }, {
                    type: 'all',
                    text: t('Chart_ShowAll'),
                    title: 'View all'
                }],
                buttonSpacing: 10,
                buttonTheme: {
                    width: 50,
                    r: 5,
                    style: {
                        fill: '#039',
                        textAlign: 'center'
                    },
                    states: {
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                },
            },
            scrollbar: {
                enabled: false
            },
            title: {
                text: undefined
            },
            chart: {
                zoomType: 'xy'
            },
            yAxis: [{
                tickInterval: 100,
                showInLegend: false,
                labels: {
                    format: '{value} \u00b0C',
                    align: "left",
                    x: 0
                },
                title: {
                    enabled: false
                },
                opposite: false
            }],
            xAxis: [{
                type: 'datetime',
                crosshair: true,
            }],
            series: [{
                name: t('Forecast_HeatSum'),
                type: 'spline',
                yAxis: 0,
                data: props.data.temperatures,
                tooltip: {
                    valueDecimals: 0,
                    valueSuffix: '{value} \u00b0C'
                }
            }]
        };

        return options;
    };

    const HC_getDValueChartOptions = (dValueData) => {
        const options = {
            credits: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1' + t('Chart_MonthText'),
                    title: 'View 1 month'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3' + t('Chart_MonthText'),
                    title: 'View 3 months'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6' + t('Chart_MonthText'),
                    title: 'View 6 months'
                }, {
                    type: 'all',
                    text: t('Chart_ShowAll'),
                    title: 'View all'
                }],
                buttonSpacing: 10,
                buttonTheme: {
                    fill: '#eee',
                    width: 50,
                    r: 5,
                    style: {
                        color: '#039',
                        fontWeight: 'bold'
                    },
                    states: {
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                },
            },
            scrollbar: {
                enabled: false
            },
            title: {
                text: undefined
            },
            chart: {
                zoomType: 'xy'
            },
            yAxis: [{
                tickInterval: 100,
                showInLegend: false,
                labels: {
                    format: '{value}',
                    align: "left",
                    x: 0
                },
                title: {
                    enabled: false
                },
                opposite: false,
                min: 500,
                max: 800
            }],
            xAxis: [{
                type: 'datetime',
                crosshair: true,
            }],
            series: [
                {
                    name: t('DValue_Real'),
                    type: 'area',
                    yAxis: 0,
                    color: '#A3CD91',
                    data: dValueData.realDValue,
                    tooltip: {
                        valueDecimals: 0,
                        valueSuffix: ' '
                    }
                },
                {
                    name: t('DValue_Predicted'),
                    type: 'area',
                    color: '#CCC7A9',
                    yAxis: 0,
                    data: dValueData.predictedDvalue,
                    tooltip: {
                        valueDecimals: 0,
                        valueSuffix: '{value} '
                    }
                }
            ]
        };

        return options;
    };

    const HC_getGrowthStageOptions = (growthData) => {
        const options = {
            credits: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1' + t('Chart_MonthText'),
                    title: 'View 1 month'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3' + t('Chart_MonthText'),
                    title: 'View 3 months'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6' + t('Chart_MonthText'),
                    title: 'View 6 months'
                }, {
                    type: 'all',
                    text: t('Chart_ShowAll'),
                    title: 'View all'
                }],
                buttonSpacing: 10,
                buttonTheme: {
                    width: 50,
                    r: 5,
                    style: {
                        color: '#039',
                        fontWeight: 'bold'
                    },
                    states: {
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                },
            },
            scrollbar: {
                enabled: false
            },
            title: {
                text: undefined
            },
            chart: {
                zoomType: 'xy'
            },
            yAxis: [{
                tickInterval: 100,
                showInLegend: false,
                labels: {
                    format: '{value}',
                    align: "left",
                    x: 0
                },
                title: {
                    enabled: false
                },
                opposite: false,
                max: 100
            }],
            xAxis: [{
                type: 'datetime',
                crosshair: true,
            }],
            series: [
                {
                    name: t('GrowthStage_Graph_Real'),
                    type: 'area',
                    yAxis: 0,
                    color: '#A3CD91',
                    data: growthData.realValues,
                    tooltip: {
                        valueDecimals: 0,
                        valueSuffix: ' '
                    }
                }, {
                    name: t('GrowthStage_Graph_Predicted'),
                    type: 'area',
                    color: '#CCC7A9',
                    yAxis: 0,
                    data: growthData.predictedValues,
                    tooltip: {
                        valueDecimals: 0,
                        valueSuffix: '{value} '
                    }
                }, {
                    name: t('GrowthStage_Graph_Point'),
                    color: '#000000',
                    yAxis: 0,
                    data: growthData.t1Values,
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 4
                    },
                    lineWidth: 0,
                    tooltip: {
                        valueDecimals: 0,
                        valueSuffix: '{value} '
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0
                        }
                    }

                },
                //{
                //    name: t('DValue_Predicted'),
                //    type: 'area',
                //    color: '#CCC7A9',
                //    yAxis: 0,
                //    data: dValueData.predictedDvalue,
                //    tooltip: {
                //        valueDecimals: 0,
                //        valueSuffix: '{value} '
                //    }
                //}
            ]
        };

        return options;
    };

    const getLangOptions = () => {
        return {
            shortMonths: [t('MonthShort_January'), t('MonthShort_February'), t('MonthShort_March'),
            t('MonthShort_April'), t('MonthShort_May'), t('MonthShort_June'),
            t('MonthShort_July'), t('MonthShort_August'), t('MonthShort_September'),
            t('MonthShort_October'), t('MonthShort_November'), t('MonthShort_December')],
            months: [t('Month_January'), t('Month_February'), t('Month_March'),
            t('Month_April'), t('Month_May'), t('Month_June'),
            t('Month_July'), t('Month_August'), t('Month_September'),
            t('Month_October'), t('Month_November'), t('Month_December')],
            weekdays: [t('WeekDay_Monday'), t('WeekDay_Tuesday'), t('WeekDay_Wednesday'), t('WeekDay_Thursday'), t('WeekDay_Friday'), t('WeekDay_Saturday'), t('WeekDay_Sunday')]
        };
    };

    const highcharts =
        <>
            {
                isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        constructorType={'stockChart'}
                    />
            }
        </>;

    const chartJs =
        <>
            {isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                <>
                    {chartData ?
                        <>
                            <ButtonGroup className="chart-zoom-select">
                                {zoomSelections.map((radio, i) => (
                                    <ToggleButton
                                        key={i}
                                        id={radio.name + i}
                                        type="radio"
                                        variant="outline-success"
                                        name="radio"
                                        value={radio.value}
                                        checked={zoom === radio.value}
                                        onChange={(e) => {
                                            setZoom(e.currentTarget.value);
                                            resetChartData(e.currentTarget.value);
                                        }}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            {chartType === 'LINE' ? 
                                <Line data={chartData} options={chartOptions} plugins={[annotationPlugin, tooltipLinePlugin]} />
                                : ''}
                            {chartType === 'COLUMN' ?
                                <Bar data={chartData} options={chartOptions} plugins={[annotationPlugin, tooltipLinePlugin]} />
                            : ''}
                        </>
                        : t('Chart_Error')}
                </>}
        </>;


    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {chartLibrary === chartLib.highCharts ? highcharts : chartJs}
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ChartModal;