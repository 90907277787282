import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
//import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock';
import { chartMode, chartLib } from '../../constants';
import { MeasurementType } from '../../Constants/MeasurementTypes.js';
import { getContent, getContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { dayJsFormatDate } from '../../commonFunctions.js'
import { getRainSumChartData, getTemperatureChartData, getTemperatureChartOptions, getRainSumChartOptions, getDValueChartData, getDValueChartOptions, getGrowthStageChartData, getGrowthStageOptions } from '../../Charts/ChartJsOptions.js' 
import {
    getSelectedLang, getSelectedForecastStation, getSelectedHistoryStation, getSelectedYear
} from '../../common';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import windDirectionPlugin from '../ComponentStyles/windDirectionPlugin.js';

import '../../css/general.css'
import '../../css/farmPlot.css'

/* ChartJS */
import ChartJs from "chart.js/auto";
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, parseISO } from "date-fns";

const WeatherChartModal = (props) => {
    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [chartData, setChartData] = useState(null);
    const [stationData, setStationData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [zoom, setZoom] = useState('1');
    const zoomSelections = [
        { name: t('Time_Week'), value: '1' },
        { name: t('Time_ThreeDays'), value: '2' },
        { name: "24h", value: '3' }
    ]

    const tooltipLinePlugin = {
        id: "tooltipLine",
        afterDraw: (chart) => {
            if (chart.tooltip?.opacity === 1) {
                const { ctx } = chart;
                const { caretX } = chart.tooltip;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(caretX, topY - 5);
                ctx.lineTo(caretX, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';
                ctx.stroke();
                ctx.restore();
            }
        }
    };

    const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true
                }
            },
            tooltip: {
                usePointStyle: true,
                backgroundColor: 'rgba(84, 84, 84, 0.8)'
            }
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: zoom === '1' ? 7 : 3,
                    maxRotation: 0,
                    align: 'start',
                    callback: (value, index) => {
                        if (zoom === '3') {
                            var labels = stationData.graphData.timeStamps.map(t => dayjs(new Date(t)));
                            // yesterday
                            var yesterday = dayjs().subtract(1, 'day');
                            labels = labels.filter((l, i) => l > yesterday);
                            return labels[index].format("DD.MM, HH:mm");
                        }
                        else if (zoom === '2') {
                            var labels = stationData.graphData.timeStamps.map(t => dayjs(new Date(t)));
                            // threedays
                            var threedays = dayjs().subtract(3, 'day');
                            labels = labels.filter((l, i) => l > threedays);
                            return labels[index].format("DD.MM, HH:mm");
                        }

                        return dayjs(stationData.graphData.timeStamps[index]).format('DD.MM');
                    }
                },
                grid: {
                    display: false,
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left'
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        }
    };

    const getChartOptions = () => {
        // Cloning default options to avoid changing the default options
        const opts = defaultOptions;
        opts.locale = getSelectedLang();

        // if delta chosen, show background colors for delta values. Delta is always using y axis and not y1.
        //if (selectedItems.find(t => t === MeasurementType.DeltaT)) {
        //    opts.plugins.annotation = {
        //        annotations: {
        //            box1: getBox('rgba(255, 0, 0, 0.1)', null, 1),
        //            box2: getBox('rgba(255,165,0, 0.1)', 1, 2),
        //            box3: getBox('rgba(0, 255, 0, 0.1)', 2, 8),
        //            box4: getBox('rgba(255,165,0, 0.1)', 8, 12),
        //            box5: getBox('rgba(255, 0, 0, 0.1)', 12, null),
        //        }
        //    }
        //}
        //// If only one measurement type selected, hide y1 axis
        //opts.scales.y1.display = selectedItems.length > 1;

        return opts;
    };

    const filterData = (data, type) => {
        var chartData = data.filter(v => v.measurementType === type);

        if (zoom === '3') {
            var yesterday = dayjs().subtract(1, 'day');
            chartData = chartData.filter((l, i) => dayjs(l.timeValue) > yesterday);
        }
        else if (zoom === '2') {
            var threedays = dayjs().subtract(3, 'day');
            chartData = chartData.filter((l, i) => dayjs(l.timeValue) > threedays);
        }

        return chartData.map(v => v.value);
    }

    const getChartData = () => {

        // Colors for the lines in the chart
        const lineColors = ['#1242A3', '#50A82A'];
        const axisIds = ['y', 'y1'];

        var labels = stationData.graphData.timeStamps.map(t => dayjs(new Date(t)));
        if (zoom === '3') {
            var yesterday = dayjs().subtract(1, 'day');
            labels = labels.filter((l, i) => l > yesterday);
        }
        else if (zoom === '2') {
            var threedays = dayjs().subtract(3, 'day');
            labels = labels.filter((l, i) => l > threedays);
        }

        labels = labels.map(t => t.format("DD.MM, HH:mm"));
        var types = selectedItem;

        //// If delta chosen, needs to be set first for y axis to get background color correctly (annotation plugin).
        //if (selectedItems.find(t => t === MeasurementType.DeltaT)) {
        //    types = types.sort((a, b) => a === MeasurementType.DeltaT ? -1 : 1);
        //}

        const datasets = types.map((type, index) => ({
            label: stationData.graphData.values.find(d => d.measurementType === type).name,
            data: filterData(stationData.graphData.values, type),
            fill: false,
            //tension: 1.4,
            showLine: true,
            pointStyle: 'circle',
            measurementType: type,
            windDirection: stationData.graphData.values.filter(v => v.measurementType === MeasurementType.WindSpeed).map(v => v.windDirection),
            pointRadius: 0,
            borderColor: lineColors[index],
            backgroundColor: lineColors[index],
            shadingData: stationData.graphData.values.filter(v => v.measurementType === type).map(v => v),
            yAxisID: axisIds[index],
        }));

        return { labels, datasets };
    }

    useEffect(() => {
        setIsLoading(false);
    });

    useEffect(() => {
        var selectedNames = props.selectedItem;
        selectedNames = selectedNames.map(type => props.data.graphData.values.find(d => d.measurementType === type).name);
        setTitle(selectedNames.join(', '));
        setSelectedItem(props.selectedItem);
        setStationData(props.data);
        setIsLoading(false);
    }, [props.data]);

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, tooltipLinePlugin, windDirectionPlugin);

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                        <>
                            <ButtonGroup className="weather-zoom-group">
                                {zoomSelections.map((radio, i) => (
                                    <ToggleButton
                                        key={i}
                                        id={radio.name + i}
                                        type="radio"
                                        variant="primary"
                                        name="radio"
                                        className="weather-zoom-radio-button"
                                        value={radio.value}
                                        checked={zoom === radio.value}
                                        onChange={(e) => {
                                            setIsLoading(true);
                                            setZoom(e.currentTarget.value);
                                        }}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            {stationData &&

                                <div style={{
                                    width: '100%',
                                    minHeight: 450
                                }}>
                                    <Line plugins={[annotationPlugin, tooltipLinePlugin, windDirectionPlugin]} redraw={true}
                                        options={getChartOptions()} data={getChartData()} />
                                </div>}
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WeatherChartModal;