import * as dayjs from 'dayjs'
import { gardenPlantTypes } from './Constants/GardenPlantsConstants';

export const groupByKey = (array, key) => {
    return array.reduce(function (obj, x) {
        (obj[x[key]] = obj[x[key]] || []).push(x);
        return obj;
    }, {});
}
export const groupByMarked = (array, markedKey, nonMarkedKey) => {
    var marked = {}
    var nonMarked = {}

    for (var i = 0; i < array.length; i++) {
        var m = array[i][markedKey];
        var n = array[i][nonMarkedKey];
        if (m) {
            if (!marked[m]) { marked[m] = []; }
            marked[m].push(array[i]);
        }
        if (!m && n) {
            if (!nonMarked[n]) { nonMarked[n] = []; }
            nonMarked[n].push(array[i]);
        }

    }
    return [{ ...marked }, { ...nonMarked }];
}

export let groupFertilizers = function (ferts) {
    let combineArr = [];
    ferts.forEach(function (element) {
        let object = Object.assign({}, element);

        object.fertNo = element.pesticideId ? element.product + '_' : element.product;
        object.subList = [];

        //if (element.pesticideId) {
        //    let s = combineArr.find(c => c.pesticideId === element.pesticideId);
        //    if (s) {
        //        s.subList.push(element);
        //    }
        //    else {
        //        object.subList.push(element);
        //        combineArr.push(object);
        //    }
        //}
        //else
        {
            object.subList.push(element);
            combineArr.push(object);
        }
    });

    var productGroup = groupByKey(combineArr, 'fertNo');

    var groupedByDate = [];
    for (var i in productGroup) {

        var productGroup2 = groupByKey(productGroup[i], 'plannedDate');

        for (var j in productGroup2) {
            groupedByDate.push(productGroup2[j]);
        }
    };

    return groupedByDate;
};

export let groupPesticides = function (pests) {
    let flattenObjects = [];
    pests.forEach(function (element) {
        if (element.pesticides.length > 0 || element.fertilizings.length > 0) {
            const object = {
                plannedDate: null,
                growthInformation: {},
                productsGroupNo: [],
                fertilizers: [],
                pesticides: [],
            };

            var obj = Object.assign({}, element);

            if (obj.growthInformation && (obj.growthInformation.plannedDate || obj.growthInformation.date)) {
                object.growthInformation = obj.growthInformation;
                object.plannedDate = obj.growthInformation.plannedDate;

                obj.pesticides.forEach(p => {
                    if (p.pesticideRowKey) {
                        object.productsGroupNo.push(p.product);
                        object.pesticides.push(p);
                    }
                });

                obj.fertilizings.forEach(f => {
                    object.productsGroupNo.push('f' + f.product);
                    object.fertilizers.push(f);
                });
            }

            if (object.productsGroupNo.length) flattenObjects.push(object);
        }
    });

    var productGroup = groupByKey(flattenObjects, 'productsGroupNo');

    var groupedByDate = [];
    for (var i in productGroup) {

        var productGroup2 = groupByKey(productGroup[i], 'plannedDate');

        for (var j in productGroup2) {
            groupedByDate.push(productGroup2[j]);
        }
    };
    return groupedByDate;
};

export const toggleActive = (activeId, cardId, setActiveId) => {
    if (activeId === cardId) {
        setActiveId(0);
    } else {
        setActiveId(cardId);
    }
}

export const formatPlannedDate = (date, t) => {
    if (!date) return "";
    var year = date.slice(0, 4);
    var month = Number(date.slice(4, 6));
    var seasonCode = "";
    if ([1, 2].includes(month)) {
        seasonCode = 'Season_Winter';
    } else if ([3, 4, 5].includes(month)) {
        seasonCode = 'Season_Spring';
    } else if ([6, 7].includes(month)) {
        seasonCode = 'Season_Summer';
    } else if (month === 8) {
        seasonCode = 'Season_AutumnSummer';
    } else if ([9, 10, 11].includes(month)) {
        seasonCode = 'Season_Autumn';
    } else if (month === 12) {
        seasonCode = 'Season_Winter';
    }
    return t(seasonCode) + ' ' + year;
}

export const getGrowthStageText = (growthStage, t) => {
    if (growthStage >= 10 && growthStage <= 19)
        return t('GrowthStage_Seedling');
    if (growthStage >= 20 && growthStage <= 29)
        return t('GrowthStage_Tillering');
    if (growthStage >= 30 && growthStage <= 39)
        return t('GrowthStage_StemElongation');
    if (growthStage >= 40 && growthStage <= 49)
        return t('GrowthStage_Booting');
    if (growthStage >= 50 && growthStage <= 59)
        return t('GrowthStage_EarEmergence');
    if (growthStage >= 60 && growthStage <= 69)
        return t('GrowthStage_Flowering');
    if (growthStage >= 70 && growthStage <= 79)
        return t('GrowthStage_SeedDevelopment');
    if (growthStage >= 80 && growthStage <= 99)
        return t('GrowthStage_Ripening');

    return t('GrowthStage_Zero');
}

export const selectFertilizerName = (fert) => {
    if (!fert) return '';

    if (fert.productName)
        return fert.productName;
    else if (fert.description)
        return fert.description
    else
        return ''
}

export let checkSelectedPlants = (selectedPlotsData) => {
    let plants = [];
    selectedPlotsData.map(function (a) {
        if (plants.indexOf(a.plantId) === -1) plants.push(a.plantId);
    });
    return plants;
}

export let checkSowButtonState = (selectedPlotsData) => {
    let plants = checkSelectedPlants(selectedPlotsData);
    if (plants.length === 1 && plants.some(p => p !== 0))
        return false; // disabled false
    else
        return true;
};

export let checkPlotsHaveNoPlant = (selectedPlotsData) => {
    let plants = checkSelectedPlants(selectedPlotsData);
    if (plants.length === 1 && plants.some(p => p !== 0))
        return false; // disabled false
    else
        return true;
};

export let dayJsFormatDate = (date) => {
    return dayjs(date).format("DD.MM.YYYY");
}

// sow, fertilize modal amount max value
export let valueMax = (inputObj) => {
    const { value } = inputObj;
    if (value < 1000000) return true;
    return false;
};

// observation density
export let valueMaxObservation = (inputObj) => {
    const { value } = inputObj;
    if (value < 100000) return true;
    return false;
};

export let valueMaxArea = (inputObj) => {
    const { value } = inputObj;
    if (value < 1000) return true;
    return false;
};

export let valueMaxDryMaterialPercentage = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100) return true;
    return false;
};


export let round = (value) => {
    return Math.round(value * 100) / 100;
}

// Calculations of sum of areas and amounts to marking modals

export const updateCalculativeArray = (calculationCombinedData, floatValue, i, j, k, pest) => {
    let newArr = [...calculationCombinedData];
    // For mark pescticides modal
    if (k && pest) {
        // Pesticides
        if (pest === true)
            newArr[i].objects[j].combinedAmounts.find(p => p.product === newArr[i].objects[j].combinedData.pesticides[k].product).amount = floatValue;
        // Fertilizers
        else if (pest === false)
            newArr[i].objects[j].combinedAmounts.find(p => p.product === newArr[i].objects[j].combinedData.fertilizer[k].product).amount = floatValue;
    }
    // For mark sows & fertilizers
    else { 
        newArr[i].objects[j].combinedData.amount = floatValue;
    }

    return newArr;
}

export const GardenPlantCheck = (plantId) => {
    return gardenPlantTypes.includes(plantId.substring(0, 2));
};

export const IsIrrigation = (value) => {
    const spreadingMethod_irrigation = 6;
    if (value === spreadingMethod_irrigation) {
        return true;
    }

    return false;
}

export const getProPlusUrl = (lang) => {
    return lang === 'fi' ? 'https://www.minunmaatilani.fi/tutustu-pessl-saaasemaan' : 'https://www.minunmaatilani.fi/sv/bekanta-dig-med-pessl-vaderstationen/';
}