import { fi } from 'date-fns/locale' 
import * as dayjs from 'dayjs';

export const getGrowthStageChartData = (zoomValue, growthData, t) => {
    var realValues = growthData.realValues.map(value => {
        return {x: new Date(value[0]), y: value[1]};
    });
    var predictedValues = growthData.predictedValues.map(value => {
        return {x: new Date(value[0]), y: value[1]};
    });
    var t1Values = growthData.t1Values.map(value => {
        return {x: new Date(value[0]), y: value[1]};
    });

    var date = new Date(realValues[realValues.length - 1].x);
    if (zoomValue === '1') {
        date.setMonth(date.getMonth() - 1);
        realValues = realValues.filter(data => data.x >= date);
        predictedValues = predictedValues.filter(data => data.x >= date);
        t1Values = t1Values.filter(data => data.x >= date);
    }

    var chartData = {
        labels: realValues.map(data => data.x),
        datasets: [
            {
                label: t('GrowthStage_Graph_Real'),
                borderColor: '#A3CD91',
                backgroundColor: 'rgba(163, 205, 145, 0.7)',
                color: '#A3CD91',
                fill: true,
                data: realValues,
                showLine: true,
                pointStyle: 'circle',
                pointRadius: 0,
                order: 2,
                parsing: false
            },
            {
                label: t('GrowthStage_Graph_Predicted'),
                borderColor: '#FFA07A',
                backgroundColor: 'rgba(255, 160, 122, 0.7)',
                color: '#FFA07A',
                fill: true,
                data: predictedValues,
                showLine: true,
                pointStyle: 'circle',
                pointRadius: 0,
                order: 2,
                parsing: false
            },
            {
                label: t('GrowthStage_Graph_Point'),
                borderColor: '#000000',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#000000',
                pointRadius: 6, // Customize point size
                pointHoverRadius: 8,
                hitRadius: 5,
                showLine: false,
                data: t1Values,
                order: 1,
                parsing: false
            }
        ]
    };

    return chartData;
}

export const getDValueChartData = (zoomValue, growthData, t) => {
    var realDValue = growthData.realDValue.map((value) => {
        return { x: new Date(value[0]), y: value[1] };
    });
    var predictedDValue = growthData.predictedDvalue.map((value) => {
        return { x: new Date(value[0]), y: value[1] };
    });

    var date = new Date(realDValue[realDValue.length - 1].x);
    if (zoomValue === '1') {
        date.setMonth(date.getMonth() - 1);
        realDValue = realDValue.filter((data) => data.x >= date);
        predictedDValue = predictedDValue.filter((data) => data.x >= date);
    }

    var chartData = {
        labels: realDValue.map((data) => data.x),
        datasets: [
            {
                label: t('DValue_Real'),
                borderColor: '#A3CD91',
                backgroundColor: 'rgba(163, 205, 145, 0.7)',
                color: '#A3CD91',
                fill: true,
                data: realDValue,
                showLine: true,
                pointStyle: 'circle',
                pointRadius: 0,
                parsing: false
            },
            {
                label: t('DValue_Predicted'),
                borderColor: '#FFA07A',
                backgroundColor: 'rgba(255, 160, 122, 0.7)',
                color: '#FFA07A',
                fill: true,
                data: predictedDValue,
                showLine: true,
                pointStyle: 'circle',
                pointRadius: 0,
                parsing: false
            }
        ]
    };

    return chartData;
}

export const getRainSumChartData = (zoomValue, data, t) => {
    var rains = data.dailyRains.map((value) => {
        return { x: new Date(value[0]), y: value[1] };
    });

    // get date based on zoom selection and last data point
    var date = new Date(rains[rains.length - 1].x);
    if (zoomValue === '1') {
        date.setMonth(date.getMonth() - 1);
        rains = rains.filter((data) => data.x >= date);
    }

    const chartData = {
        labels: rains.map((data) => data.x),
        datasets: [{
            label: t('Forecast_RainSum'),
            data: rains.map((data) => data.y),
            fill: true,
            // Colors
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75,0,192,0.4)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,0,192,0.6)',
            hoverBorderColor: 'rgba(75,0,192,1)',

            pointHoverBorderWidth: 2,
            pointHoverRadius: 5,
        }]
    };

    return chartData;
}

export const getTemperatureChartData = (zoomValue, data, t) => {
    var temperatures = data.temperatures.map((value) => {
        return { x: new Date(value[0]), y: value[1] };
    });

    // get date based on zoom selection and last data point
    var date = new Date(temperatures[temperatures.length - 1].x);
    if (zoomValue === '1') {
        date.setMonth(date.getMonth() - 1);
        temperatures = temperatures.filter((data) => data.x >= date);
    }

    const chartData = {
        labels: temperatures.map((data) => data.x),
        datasets: [{
            label: t('Forecast_HeatSum'),
            data: temperatures.map((data) => data.y),
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            pointHoverBorderWidth: 2,
            pointHoverRadius: 5,
            showLine: true,
            pointStyle: 'circle',
            pointRadius: 0,
        }]
    };

    return chartData;
}

export const getGrowthStageOptions = (tickMode, langOptions) => {
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    parser: 'dd/MM/yyyy HH:mm',
                    unit: tickMode ?? 'month',
                    tooltipFormat: 'iiii, PP',
                    unitStepSize: 1,
                    displayFormats: {
                        'day': 'dd / MM',
                        'month': 'PP',
                    }
                },
                adapters: {
                    date: {
                        locale: fi
                    }
                },
                ticks: {
                    maxRotation: 0,
                    align: 'start',
                    callback: function (value) {
                        var date = new Date(value);
                        // get month from date and the last two numbers of the year
                        return langOptions.shortMonths[date.getMonth()];
                    }
                }
            },
            y: {
                type: 'linear',
            }
        },
        plugins: {
            legend: {
                display: false
            },
        }
    };

    return options;
}

export const getDValueChartOptions = (tickMode, langOptions) => {
    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    parser: 'dd/MM/yyyy HH:mm',
                    unit: tickMode ?? 'month',
                    tooltipFormat: 'iiii, PP',
                    unitStepSize: 1,
                    displayFormats: {
                        'day': 'dd / MM',
                        'month': 'PP',
                    }
                },
                adapters: {
                    date: {
                        locale: fi
                    }
                },
                ticks: {
                    maxRotation: 0,
                    align: 'start',
                    callback: function (value) {
                        var date = new Date(value);
                        // get month from date and the last two numbers of the year
                        return langOptions.shortMonths[date.getMonth()];
                    }
                }
            },
            y: {
                type: 'linear',
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                mode: 'index',  // Show tooltip for the nearest point on the x-axis
                intersect: false,  // Show tooltip even if cursor is not exactly on the point
            },
            hover: {
                mode: 'index',  // Highlight the nearest point on the x-axis
                intersect: false,  // Highlight even if cursor is not exactly on the point
            },
        }
    };

    return options;
}

export const getRainSumChartOptions = (tickMode, langOptions) => {
    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    parser: 'dd/MM/yyyy HH:mm',
                    unit: tickMode ?? 'month',
                    tooltipFormat: 'iiii, PP',
                    unitStepSize: 1,
                    displayFormats: {
                        'day': 'dd / MM',
                        'month': 'PP',
                    }
                },
                adapters: {
                    date: {
                        locale: fi
                    }
                },
                ticks: {
                    maxRotation: 0,
                    align: 'start',
                    callback: function (value) {
                        var date = new Date(value);
                        // get month from date and the last two numbers of the year
                        return langOptions.shortMonths[date.getMonth()];
                    }
                }
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                mode: 'index',  // Show tooltip for the nearest point on the x-axis
                intersect: false,  // Show tooltip even if cursor is not exactly on the point
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + ' mm';
                        }
                        return label;
                    }
                }
            },
            hover: {
                mode: 'index',  // Highlight the nearest point on the x-axis
                intersect: false,  // Highlight even if cursor is not exactly on the point
            },
        }
    };

    return options;
}

export const getTemperatureChartOptions = (tickMode, langOptions) => {
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    parser: 'dd/MM/yyyy HH:mm',
                    unit: tickMode ?? 'month',
                    tooltipFormat: 'iiii, PP',
                    unitStepSize: 1,
                    displayFormats: {
                        'day': 'dd / MM',
                        'month': 'PP',
                    }
                },
                adapters: {
                    date: {
                        locale: fi
                    }
                },
                ticks: {
                    maxRotation: 0,
                    align: 'start',
                    callback: function (value) {
                        var date = new Date(value);
                        // get month from date and the last two numbers of the year
                        return langOptions.shortMonths[date.getMonth()];
                    }
                }
                /*
                ticks: {
                    callback: function (value) {
                        const months = getLangOptions().shortMonths;
                        const date = new Date(value);
                        // parse month from date
                        const month = date.getMonth();
                        const year = date.getFullYear().toString().substring(2);
                        return `${months[month]} '${year}`;
                    }
                }
                */
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return value + ' °C';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                usePointStyle: true,
                backgroundColor: 'rgba(84, 84, 84, 0.8)',
                mode: 'index',  // Show tooltip for the nearest point on the x-axis
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + ' °C';
                        }
                        return label;
                    }
                }
            },
        }
    };

    return options;
}