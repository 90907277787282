import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
//import Highcharts from 'highcharts'
import { MeasurementType } from '../../Constants/MeasurementTypes.js';
import { getContent, getContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { dayJsFormatDate } from '../../commonFunctions.js'
import { getRainSumChartData, getTemperatureChartData, getTemperatureChartOptions, getRainSumChartOptions, getDValueChartData, getDValueChartOptions, getGrowthStageChartData, getGrowthStageOptions } from '../../Charts/ChartJsOptions.js' 
import {
    getSelectedLang, getSelectedForecastStation, getSelectedHistoryStation, getSelectedYear
} from '../../common';
import { SiRainmeter } from "react-icons/si";
import {
    WiCloud,
    WiStrongWind,
    WiCloudy,
    WiDayCloudy,
    WiDaySunny,
    WiDaySprinkle,
    WiDayStormShowers,
    WiDayRain,
    WiUmbrella,
    WiThermometer
} from "react-icons/wi";
import { IoIosArrowRoundUp } from "react-icons/io";

import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import '../../css/general.css'
import '../../css/farmPlot.css'
import '../../css/weatherForecast.css'

dayjs.extend(utc);
dayjs.extend(timezone);

const WeatherChartModal = (props) => {
    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(t('WeatherForecasts'));
    const [chartData, setChartData] = useState(null);
    const [daySelection, setDaySelection] = useState(null);
    const [selectedDay, setSelectedDay] = useState('1');
    const [hourlyData, setHourlyData] = useState(null);
    const [dailyData, setDailyData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    /* VARIABLES FROM MMWISU */
    const measurementsDay = [MeasurementType.TemperatureMean, MeasurementType.WindSpeedMean, MeasurementType.Precipitation, MeasurementType.ProbabilityOfPrec];
    const measurementsHour = [MeasurementType.AirTemperature, MeasurementType.WindSpeed, MeasurementType.Precipitation, MeasurementType.ProbabilityOfPrec];

    useEffect(() => {
        if (!props.data.dayForeCast.length) {
            // If forecast data is empty, set data to null
            setHourlyData(null);
            setDailyData(null);
            return;
        }

        dayjs.locale(getSelectedLang());
        var dateSelection = [];
        var days = props.data.dayForeCast;
        days.forEach((day, i) => {
            var weekDay = dayjs(day.date).format('ddd');
            dateSelection.push({ name: weekDay + ' ' + dayjs(day.date).format('DD.MM'), value: i.toString(), data: day });
        });

        setDaySelection(dateSelection);
        setSelectedDay('0');
        setHourlyData(dateSelection[0].data.hourForeCast);
        setDailyData(dateSelection);
    }, []);

    const handleSelect = (selectedIndex) => {
        setSelectedDay(selectedIndex.toString());
        var selectedDayData = daySelection.find(d => d.value === selectedIndex.toString()).data;
        setHourlyData(selectedDayData.hourForeCast);
        setActiveIndex(selectedIndex);
    }

    const getMeasurementValue = (item, type, isDay) => {
        var text = "";
        var measurement = item.values.find((x) => x.measurementType === type);
        //var color = theme.palette.primary.main;
        //var size = isDay ? 25 : 20;

        if (measurement) {
            var itemValue = measurement.value;
            var val = itemValue || itemValue === 0 ? itemValue.toLocaleString(t('locale'), { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "-";
            text = val + " " + measurement.unit;
        }
        var windDirectionIcon = measurement && measurement.windDirection
            ? <IoIosArrowRoundUp fontSize={25} style={{ transform: 'rotate(' + measurement.windDirection + 'deg)' }} />
            : "";
        // WiRainDrop
        var title = t("lt24241");
        if (type === MeasurementType.WindSpeedMean || type === MeasurementType.WindSpeed)
            title = t("lt24244");
        else if (type === MeasurementType.Precipitation)
            title = isDay ? t("lt24242") : t("lt24272")
        else if (type === MeasurementType.ProbabilityOfPrec)
            title = t("lt24273");

        var xs = isDay ? 5 : 2;
        if (type === MeasurementType.AirTemperature && !isDay) xs = 2;
        else if (type === MeasurementType.WindSpeed && !isDay) xs = 4;

        return (
            <React.Fragment key={type}>
                {isDay ?
                    <Col className="weather-forecast-col" direction={isDay ? 'row' : { xs: 'column', md: 'row' }} gap={1}
                        xs={xs} >
                        {type === MeasurementType.TemperatureMean && <WiThermometer size={25} />}
                        {type === MeasurementType.AirTemperature && <WiThermometer size={25} />}
                        {type === MeasurementType.WindSpeedMean && <WiStrongWind size={25} />}
                        {type === MeasurementType.WindSpeed && <WiStrongWind size={25} />}
                        {type === MeasurementType.Precipitation && <SiRainmeter size={20} />}
                        {type === MeasurementType.ProbabilityOfPrec && <WiUmbrella size={25} />}

                        <label>{text}</label>
                        {!isDay && type === MeasurementType.WindSpeed && windDirectionIcon}
                    </Col>
                    : <Col className="weather-forecast-col" direction={isDay ? 'row' : { xs: 'column', md: 'row' }} gap={1}
                        xs={xs} >
                        <Row>
                            <Col>
                                {type === MeasurementType.TemperatureMean && <WiThermometer size={25} />}
                                {type === MeasurementType.AirTemperature && <WiThermometer size={25} />}
                                {type === MeasurementType.WindSpeedMean && <WiStrongWind size={25} />}
                                {type === MeasurementType.WindSpeed && <WiStrongWind size={25} />}
                                {type === MeasurementType.Precipitation && <SiRainmeter size={20} />}
                                {type === MeasurementType.ProbabilityOfPrec && <WiUmbrella size={25} />}
                            </Col>
                        </Row>

                        <Row>
                            <Col>{text}</Col>
                            {!isDay && type === MeasurementType.WindSpeed ? <Col className="forecast-wind-dir" xs={1}>{windDirectionIcon}</Col> : ''}
                        </Row>
                    </Col>
                }
            </React.Fragment>
        )
    };

    const getCloudIcon = (day, isDay) => {
        var precipitation = day.values.find((x) => x.measurementType === MeasurementType.Precipitation)?.value;
        var probability = day.values.find((x) => x.measurementType === MeasurementType.ProbabilityOfPrec)?.value;

        // For day calculate value from hour values
        var cloudCover = isDay
            ? day.hourForeCast.map(h => h.values.find((h) => h.measurementType === MeasurementType.CloudCover && h.value >= 0)?.value)
                .reduce((acc, val) => acc + val, 0) / day.hourForeCast.length
            : day.values.find((x) => x.measurementType === MeasurementType.CloudCover)?.value;

        var size = isDay ? 70 : 30;
        var color = "black";

        if (!precipitation || !probability || probability < 30) {
            if (!cloudCover) return <div><WiDaySunny color={color} size={size} />{t('Weather_Clear')}</div>;
            if (cloudCover < 30) return <div><WiDayCloudy color={color} size={size} />{ t('Weather_PartlyCloudy') }</div >;
            if (cloudCover < 70) return <div><WiCloud color={color} size={size} />{ t('Weather_Cloudy') }</div >;
            return <div><WiCloudy color={color} size={size} />{ t('Weather_Cloudy') }</div >;
        }
        if (precipitation < 0.5) return <div><WiDaySprinkle color={color} size={size} />{t('Weather_Sprinkle')}</div >;
        if (precipitation < 4) return <div><WiDayRain color={color} size={size} />{t('Weather_Rain')}</div >;
        return <div><WiDayStormShowers color={color} size={size} />{t('Weather_Rain')}</div >;

    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {daySelection ?
                        <ButtonGroup className="weather-forecast-date-select">
                            {daySelection.map((radio, i) => (
                                <ToggleButton
                                    key={i}
                                    id={radio.name + i}
                                    type="radio"
                                    variant="primary"
                                    name="radio"
                                    className="forecast-radio-button"
                                    value={radio.value}
                                    checked={selectedDay === radio.value}
                                    onChange={(e) => {
                                        setSelectedDay(e.currentTarget.value);
                                        var selectedDayData = daySelection.find(d => d.value === e.currentTarget.value).data;
                                        setHourlyData(selectedDayData.hourForeCast);
                                        setActiveIndex(parseInt(e.currentTarget.value));
                                    }}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                        : ''}

                    {dailyData ?
                        <Carousel activeIndex={activeIndex} variant="dark" interval={null} indicators={false} onSelect={handleSelect}>
                            {dailyData.map((item, i) => (
                                <Carousel.Item className="weather-forecast-carousel" key={i}>
                                    <Carousel.Caption>
                                        <h4 className="date-header">{dayjs(item.data.date).format('ddd') + ' ' + dayjs(item.data.date).format('DD.MM')}</h4>
                                        <Row>{getCloudIcon(item.data, true)}</Row>
                                        <Row className="weather-forecast-day-row">
                                            {measurementsDay.map((type, i) => (
                                                getMeasurementValue(item.data, type, true)
                                            ))}
                                        </Row>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        : ''}

                    {hourlyData ?
                        <Container>
                            {hourlyData.map((item, i) => (
                                <Row key={i} className={"weather-forecast-row"}>
                                    <Col className="weather-forecast-col forecast-time" xs={2}>{dayjs(item.date).format('HH:mm')}</Col>
                                    {measurementsHour.map((type, i) => (
                                        getMeasurementValue(item, type, false)
                                    ))}
                                </Row>
                            ))}
                        </Container>
                        : t('Forecast_Not_Available')}

                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WeatherChartModal;