export const MeasurementType = {
    "Unknown": 0,
    "Battery": 1,
    "SolarPanel": 2,
    "Precipitation": 3,
    "SolarRadiation": 4,
    "LeafWetness": 5,
    "AirTemperature": 6,
    "RelativeHumidity": 7,
    "SunshineDuration": 8,
    "WindSpeed": 9,
    "WindDirection": 10,
    "WindGust": 11,
    "SoilPermeability": 12,
    "SoilMoisture": 13,
    "SoilTemperature": 14,
    "DeltaT": 15,
    "VPD": 16,
    "DewPoint": 17,
    "DailyET0": 18,
    "WindOrientation": 19,
    "Sunrise": 20,
    "Sunset": 21,
    "Midnight": 22,
    "SunshineTime": 23,
    "CloudCover": 24,
    "AirSurfacePressure": 25,
    "ProbabilityOfSnow": 26,
    "Pictocode": 27,
    "SeaLevelPressure": 28,
    "ProbabilityOfPrec": 29,
    "IsDayLight": 30,
    "SoilTemperature0to10cm": 31,
    "GlobalRadiationSensibleHeatFlux": 32,
    "WebBulbTemperature": 33,
    "GlobalRadiationGHI": 34,
    "SnowCover": 35,
    "TemperatureMin": 36,
    "TemperatureMax": 37,
    "TemperatureMean": 38,
    "WindSpeedMin": 39,
    "WindSpeedMax": 40,
    "WindSpeedMean": 41,
    "RelativeHumidityMin": 42,
    "RelativeHumidityMax": 43,
    "RelativeHumidityMean": 44,
    "Risk": 45,
    "AphidRisk": 46,
    "Infection": 47,
    "Moderate": 48,
    "Resistant": 49,
    "Susceptible": 50,
    "SprayInterval": 51,
    "SprayDemand": 52,
    "DiseaseSeverity": 53,
    "SeverityDSV": 54,
    "PNegative": 55,
    "Level": 56,
    "InfectionRisk": 57,
    "InfectionHours": 58,
    "InfectionProgress": 59,
    "InfectionStrength": 60,
    "PropagationHours": 61,
    "SmithPeriods": 62
};