import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle } from '../ComponentStyles/ButtonStyles.js'
import { getContentWithAction } from '../../apiConnect';
import { getSelectedPlot } from '../../common';
import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, formatPlannedDate, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'

const PlotModificationAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [plotModifications, setPlotModifications] = useState([]);
    const [memos, setMemos] = useState([]);
    const [plotModificationIsLoading, setPlotModificationIsLoading] = useState(true);
    const [memosIsLoading, setMemosIsLoading] = useState(true);

    const p = getSelectedPlot();

    useEffect(() => {
        setPlotModificationIsLoading(true);

        if (p && p.apNumber) {
            getContentWithAction(auth.getAccessToken(), 'plotModification', 'plotModifications', { plotId: p.apNumber }).then((modifications) => {
                //cleanup()
                setPlotModifications(modifications);
                setPlotModificationIsLoading(false);
            });
        }
    }, [props.refresh]);

    useEffect(() => {
        setMemosIsLoading(true);
        var memos = props.memos.filter(m => m.plantMemoType === 3);
        memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        setMemos(memos);
        setMemosIsLoading(false);
    }, [props.memos]);

    const getRowLetter = (row) => {
        return row.description.substring(0, 1);
    }

    return (
        <Card className="border-bottom-1">
            <Card.Header className={props.activeCard === Cards.modificationCardId ? 'active-panel' : 'inactive-panel'}>
                <OperationCardToggle classname={props.activeCard === Cards.modificationCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.modificationCardId, props.setActiveCard)} eventKey="2">
                    {t('Tilling_Cultivation')}
                    {plotModificationIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{plotModifications.length}</Badge>}
                </OperationCardToggle>
                <Button style={addButtonStyle} variant='mmfi-add-btn' className="float-right" onClick={() => {
                    props.setSelectEvent({ time: Date.now(), selectedElement: null });
                    props.setModalShow(true)
                }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
                <Card.Body>
                    <ListGroup>
                        {(plotModifications) ? plotModifications.map(p =>
                            <div key={p.number} id={p.number} className="row-margin-bot">
                                <div className='row-icon-left row-icon-operation'>{getRowLetter(p)}</div>

                                <ListGroup.Item key={p.number} style={rowStyle} className="plot-operation-row-done" action onClick={() => {
                                    props.setSelectEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true);
                                }}>

                                    <div>
                                        <span className="operation-list-name">{p.description} </span>- {p.depth ? p.depth + ' ' + p.unitText : ''} {p.count ? 'x ' + p.count + t('Counts') : ''}</div>
                                    <div>{(p.actionDate) ?
                                        <span className='row-subtext'>{t('Completed')} {dayJsFormatDate(p.actionDate)}</span>
                                        : <span className='row-subtext'>{formatPlannedDate(p.plannedDate, t)}</span>}
                                    </div>
                                </ListGroup.Item>
                                {/*<div className="set-done-box">*/}
                                {/*    {!p.actionDate ? <Button variant='success' className="float-right set-done" disabled onClick={() => console.log(true)}>{t('MarkDone')}</Button> : ''}*/}
                                {/*</div>*/}
                            </div>
                        ) : ''}
                        {!props.memosIsLoading ? memos.map(p =>
                            <div key={p.memoId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                <ListGroup.Item className="plot-memo-row" key={p.memoId} style={rowStyle} action onClick={() => {
                                    props.setMemoType(3);
                                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                    props.setMemoModalShow(true);
                                }}>
                                    <div><b> {t('Note')}</b></div>
                                    <div className='row-subtext'>{p.memo}</div>
                                </ListGroup.Item>
                                <span style={textStyle}></span>
                            </div>
                        ) : <div>
                            {t('Loading')}
                            <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                        </div>}
                        <ListGroup.Item style={addNoteRowStyle} action
                            onClick={() => {
                                props.setMemoType(3);
                                props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                                props.setMemoModalShow(true);
                            }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                            {memosIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default PlotModificationAccordionCard;